import request from '../utils/request.js'


export function getWordList(task_id) {
    return request({
        url: 'api?file=word&cmd=get_word_list&task_id=' + task_id,
        method: 'get',
    })
}


export function getName(type, id) {
    return request({
        url: 'api?file=word&cmd=get_name&type=' + type + '&id=' + id,
        method: 'get',
    })
}


export function delWord(task_id, file_id) {
    return request({
        url: 'api?file=word&cmd=del_word&task_id=' + task_id + '&file_id=' + file_id,
        method: 'get',
    })
}