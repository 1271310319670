/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import {
  getName
} from '@/api/word';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'zh',
    cancelTokens: {},
    project: {
      name: 'No proj',
      id: null,
    },
    task: {
      name: 'No task',
      id: null
    },
    taskIdArr: [],
  },
  getters: {},
  mutations: {
    set_lang(state, data) {
      state.lang = data
    },
    cancel({
      cancelTokens
    }, key) {
      if (cancelTokens[key]) {
        cancelTokens[key]('请求取消')
        delete cancelTokens[key]
      }
    },
    addToken(state, {
      key,
      cancel
    }) {
      state.cancelTokens[key] = cancel
    },
    delToken({
      cancelTokens
    }, key) {
      if (cancelTokens[key]) {
        delete cancelTokens[key]
      }

    },
    cancelAll({
      cancelTokens
    }) {
      Object.keys(cancelTokens).forEach(key => {
        cancelTokens[key]('请求取消')
      })
      cancelTokens = {}
    },
    set_project(state, data) {
      state.project = data
    },
    set_task(state, data) {
      state.task = data
    },
    set_taskArr(state, data) {
      if (data.type == 'add') {
        if (state.taskIdArr.indexOf(data.id) == -1) {
          state.taskIdArr.push(data.id)
        }
      } else if (data.type == 'del') {
        state.taskIdArr.splice(state.taskIdArr.indexOf(data.id), 1)
      }
    }
  },
  actions: {
    setLang({
      state,
      commit
    }, data) {
      commit('set_lang', data)
    },
    setProject({
      state,
      commit
    }, data) {
      getName(data.type, data.id).then(res => {
        commit('set_project', {
          name: res.result.name,
          id: data.id
        })
      })
    },
    setTask({
      state,
      commit
    }, data) {
      getName(data.type, data.id).then(res => {
        commit('set_task', {
          name: res.result.name,
          id: data.id
        })
      })
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      // storage: window.sessionStorage,
      // reducer(val) {
      // return {
      // 只储存state中的assessmentData
      // lang: val.lang
      // }
      // }
    })
  ]
})