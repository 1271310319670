<template>
	<div id="app" :style="PC ? 'padding-top:.9rem;min-width:1150px;' : ''">
		<header-nav
			v-if="PC && $route.path != '/login' && $route.path != '/regist' && $route.path != '/' && $route.path != '/introduce' && $route.path != '/preview' && $route.path != '/auth' && isMount" />
		<!-- <keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive> -->
		<router-view class="router" v-if="isRouterAlive"></router-view>

		<bottom-nav
			v-if="!PC && $route.path != '/login' && $route.path != '/regist' && $route.path != '/' && $route.path != '/introduce' && $route.path != '/preview' && $route.path != '/auth' && isMount" />
	</div>
</template>

<script>
import headerNav from "./views/headerNav.vue";
import bottomNav from './views/bottomNav.vue';
import { ISMOBILE } from '@/utils/auth';
export default {
	components: {
		headerNav,
		bottomNav
	},
	name: 'app',
	provide() {
		return {
			reload: this.reload
		}
	},
	data() {
		return {
			isRouterAlive: true,
			isMount: false,
			PC: false
		}
	},
	created() {

	},
	mounted() {
		ISMOBILE() ? this.PC = false : this.PC = true
		setTimeout(() => {
			this.isMount = true
		}, 100)
	},
	methods: {
		reload() {
			this.isRouterAlive = false
			this.$nextTick(function () {
				this.isRouterAlive = true
				this.isMount = true
			})
		},

	}
}
</script>

<style lang="less">
@import url("@/assets/css/animate.css");
@import url("@/assets/css/main.css");

</style>
