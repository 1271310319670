export function getToken() {
	return localStorage.getItem('token');
}
export function setToken(newToken) {
	return localStorage.setItem('token', newToken)
}
export function removeToken() {
	return localStorage.removeItem('token')
}

export function getUrl() {
	// return 'https://ai.sensormv.com/'
	// return 'http://test.sensormv.com/'
	// return 'http://127.0.0.1/'
	// return 'http://0.0.0.0/'
	return '/'
}

export function getVerson() {
	return '4.8'
	// return '/ve:1.0/'
}

export function ISMOBILE() {
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	return flag;
}

// 是否是web端
export function isWeb() {
	if (/ai-platform/.test(navigator.userAgent)) {
		return false
	} else {
		return true
	}
}


// 是否是私有化部署
export function isPrivate() {
	console.log(window.location.href);
	if (window.location.href.indexOf('sensor') !== -1) {
	// if (window.location.href.indexOf('127') !== -1) {
		return false
	} else {
		return true
	}
}

// 获取本地语言
export function getLang() {
	if (navigator.language == 'zh-CN') {
		return 'zh'
	} else {
		return 'en'
	}
}
