import 'babel-polyfill';

import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App'
import store from "./store";
import router from './router'
import axios from "axios";
import '@/assets/css/font.css';

import i18n from './lang'
import VueLazyload from 'vue-lazyload'
//顶部页面加载条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { isWeb } from "./utils/auth";
NProgress.set(1)
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
//路由监听
router.beforeEach((to, from, next) => {
  document.title = isWeb() ? '森赛睿AI云服务平台' : 'AI服务平台';
  store.commit('cancelAll')
  NProgress.start();
  next();
});
//路由跳转结束
router.afterEach(() => {
  NProgress.done()
  window.scrollTo(0, 0);
})


Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: require("./assets/noImg.jpg"),
})

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 动态加载语言包
import enLocale from 'element-ui/lib/locale/lang/en'; // 引入英文语言包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'; // 引入中文语言包
let lang = 'zh';
if (localStorage.getItem('vuex')) {
  lang = JSON.parse(localStorage.getItem('vuex')).lang || 'zh';
}

Vue.use(ElementUI, { locale: lang === 'en' ? enLocale : zhLocale });

Vue.prototype.$changeLanguage = function (langs) {
  if (langs === 'en') {
    Vue.use(ElementUI, { locale: enLocale });
  } else {
    Vue.use(ElementUI, { locale: zhLocale });
  }
  // 更新 store 或其他保存语言设置的地方
};


Vue.config.productionTip = false

Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")