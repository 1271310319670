<template>
    <div class="bottomNav">
        <div @click="changePage(0)">
            <img v-show="activeIndex != 0" src="../assets/svg/bottom/gl.svg" alt="">
            <img v-show="activeIndex == 0" src="../assets/svg/bottom/gla.svg" alt="">
            <span :style="activeIndex == 0 ? 'color: #2E5DD4;' : ''">概览</span>
        </div>
        <div @click="changePage(1)">
            <img v-show="activeIndex != 1" src="../assets/svg/bottom/xm.svg" alt="">
            <img v-show="activeIndex == 1" src="../assets/svg/bottom/xma.svg" alt="">
            <span :style="activeIndex == 1 ? 'color: #2E5DD4;' : ''">项目</span>
        </div>
        <div @click="changePage(3)">
            <img v-show="activeIndex != 3" src="../assets/svg/bottom/rw.svg" alt="">
            <img v-show="activeIndex == 3" src="../assets/svg/bottom/rwa.svg" alt="">
            <span :style="activeIndex == 3 ? 'color: #2E5DD4;' : ''">任务</span>
        </div>
        <div @click="changePage(2)">
            <img v-show="activeIndex != 2" src="../assets/svg/bottom/mx.svg" alt="">
            <img v-show="activeIndex == 2" src="../assets/svg/bottom/mxa.svg" alt="">
            <span :style="activeIndex == 2 ? 'color: #2E5DD4;' : ''">模型</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    props: {},
    components: {},
    data() {
        return {
            activeIndex: sessionStorage.getItem('index') || '0',
            disabled: localStorage.getItem('id') == null ? true : false,
        }
    },
    inject: ['reload'],
    computed: {},
    watch: {
        $route() {
            this.activeIndex = sessionStorage.getItem('index')
            this.disabled = localStorage.getItem('id') == null ? true : false
        },
    },
    created() { },
    mounted() { },
    methods: {
        changePage(key) {
            let index = sessionStorage.getItem('index')
            if (key == 0) {
                if (index == 0) {
                    this.reload()
                }
                else this.$router.push('/overview')
            } else if (key == 1) {
                if (index == 1) {
                    this.reload()
                }
                else this.$router.push('/project')
            } else if (key == 2) {
                this.$router.push('/moduleview')
            } else if (key == 3) {
                localStorage.setItem('changeName', 1)
                if (this.disabled) {
                    return this.$message.warning('当前未选择项目，请先选择项目')
                }
                if (index == 3) {
                    if (this.$route.path == '/roleRight') {
                        return this.$router.push('/task')
                    }
                    this.reload()
                }
                else this.$router.push('/task')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bottomNav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8.3rem;
    background-color: #fff;
}

.bottomNav {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 3rem;
            height: 3rem;
            transition: all .2s linear;
        }

        span {
            transition: all .2s linear;
            font-size: 1.2rem;
            margin-top: .1rem;
            font-weight: 700;
        }
    }
}
</style>