// routerPC.js
import {
    getToken
  } from '@/utils/auth';
export const routerM = [{
        name: getToken() ? 'overview' : 'login',
        path: '/',
        redirect: getToken() ? '/overview' : '/login' /* hpp将index改称为了login */
    },
    {
        path: "/login",
        // component: resolve => require(['../components/page/login/login.vue'], resolve)
        component: () => import('@/views/mobile/login/login.vue')
    },
    {
        path: "/overview",
        component: () => import('@/views/mobile/overview')
    },
    {
        path: "/auth",
        component: () => import('@/views/auth')
    },
    {
        name: 'imgMark',
        path: "/imgMark",
        component: () => import('@/views/mobile/imgMark')
    },
    // +项目名
    {
        name: 'project',
        path: "/project",
        component: () => import('@/views/mobile/project')
    },
    // 任务管理 taskmanagement
    {
        name: 'task',
        path: "/task",
        component: () => import('@/views/mobile/task')
    },
    {
        name: 'taskview',
        path: "/taskview/:id",
        component: () => import('@/views/mobile/taskview')
    },
    {
        name: 'moduleview',
        path: "/moduleview",
        component: () => import('@/views/mobile/moduleview'),
    },
    {
        name: 'detail',
        path: "/moduleview/detail",
        component: () => import('@/views/mobile/moduleview/detail.vue')
    },
    {
        name: 'projectlist',
        path: "/projectlist",
        component: () => import('@/views/mobile/project/projectList.vue')
    },
    {
        path: "/demo",
        component: () => import('@/components/demo')
    },
    {
        name: 'userhome',
        path: "/userhome",
        component: () => import('@/views/mobile/userHome')
    },
    {
        name: 'doc',
        path: "/doc",
        component: () => import('@/views/mobile/doc')
    },
    {
        name: 'videoMark',
        path: "/videoMark",
        component: () => import('@/views/mobile/videoMark')
    },
    {
        name: 'preview',
        path: "/preview",
        component: () => import('@/views/mobile/preview')
    },
    {
        name: 'roleRight',
        path: "/roleRight",
        component: () => import('@/views/mobile/roleRight')
    }
]