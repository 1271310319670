/* eslint-disable */
import axios from 'axios';
import router from '../router/index.js'
import Vue from 'vue'
//顶部页面加载条
import NProgress from 'nprogress';
import store from '@/store'
import 'nprogress/nprogress.css';
NProgress.set(1)
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})
import {
  Message
} from 'element-ui'

import {
  getUrl,
  getVerson
} from '@/utils/auth.js';
const service = axios.create({
  baseURL: getUrl(), //武汉森赛睿科技有限公司的统一接口
})
//请求拦截器
service.interceptors.request.use(
  config => {
    NProgress.start()
    config.headers['lang'] = store.state.lang
    config.cancelToken = new axios.CancelToken(function (cancel) {
      store.commit('addToken', {
        key: config.url,
        cancel
      })
    })
    if (localStorage.getItem('token')) {
      config.headers['token'] = localStorage.getItem('token');
      config.headers['verson'] = getVerson();
    }
    return config;
  }, error => {
    Promise.reject(error)
  }
)
//响应拦截器
service.interceptors.response.use(
  response => {
    store.commit('delToken', response.config.url)
    NProgress.done()
    if (response.data.code == 1) {
      return response.data;
    } else if (response.data.code < 1) {
      if (response.data.msg == '上传失败') {
      } else {
        Message({
          type: 'error',
          message: 'Code：' + Math.abs(response.data.code) + ',' + 'Info：' + response.data.msg
        })
      }
      return response.data
    } else {
      if (response.status == 200 && response.headers.getContentType() == 'image/jpeg') {
        return response.data
      }
      Message({
        type: 'warning',
        message: 'Code：' + Math.abs(response.data.code) + ',' + 'Info：' + response.data.msg
      })
      return response.data
    }

  }, error => {
    console.log(error, '请求失败');
    if (error.message == '请求取消') {
      console.log(1111111111);
      return Promise.reject(error)
    }
    store.commit('delToken', error.config.url)
    if (error.response) {
      console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
      if (error.response.data.msg === "token不存在1" || error.response.data.msg === 'token已失效, 已在其它地方登录' || error.response.data.msg === "token超过有效期" || error.response.data.msg === "缺少token" || error.response.data.msg === "token不存在2" || error.response.data.msg === "验证token失败2" || error.response.data.msg === "Token invalid, please log in again") {
        NProgress.done()
        store.commit('set_task', {
          id: null,
          name: 'No task'
        })
        store.commit('set_project', {
          id: null,
          name: 'No proj'
        })
        localStorage.clear()
        router.push({
          path: '/login'
        })
        Message({
          type: 'error',
          // message: error.response.data.msg + 'token过期，请重新登陆'
          message: error.response.data.msg
        })
        return Promise.reject(error)
      } else {
        NProgress.done()
        Message({
          type: 'error',
          message: 'Code：' + Math.abs(error.response.data.code) + ',' + 'Info：' + error.response.data.msg
        })
        return Promise.reject(error)
      }
    } else {
      NProgress.done()
      Message({
        type: 'error',
        message: 'Code：' + error.code + ',' + 'Info：' + error.message
      })
      return Promise.reject(error)
    }
  }
)

export default service;